import styles from "./Stats.module.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import medal from "../../../media/medal.png";
import CreditsMoney from "../../../media/silver-pepe-bg.png";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import { useSpring, animated } from "@react-spring/web";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import moment from "moment";
import { UserContext } from "../../../context/UserContext";
import { useTonAddress } from "@tonconnect/ui-react";
import WalletModal from "../WalletModal";
import { AuthContext } from "../../../context/AuthContext";
import { getReadableNumber } from "../../../utils/helpers";
import rank1Icon from "../../../media/rank1Icon.png";
import rank2Icon from "../../../media/rank2Icon.png";
import rank3Icon from "../../../media/rank3Icon.png";
import ppLevel from "../../../media/ppLevel.png";
import frogImg from "../../../media/frogImg.png";
import bronzeIcon from "../../../media/bronze.png";
import silverIcon from "../../../media/silver.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import diamondIcon from "../../../media/diamond.png";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import rookie from "../../../media/rookie.png";
import bluecheck from "../../../media/bluecheck.png";

const rankIcons = {
  1: bronzeIcon,
  2: silverIcon,
  3: goldIcon,
  4: platinumIcon,
  5: diamondIcon,
  6: blueDiamondIcon,
  7: crownDiamondIcon,
};

const Stats = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [page, setPages] = useState("stats");
  const [visible, setVisible] = useState(false);
  const [dailyRanking, setDailyRanking] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [allTimeRanking, setAllTimeRanking] = useState([]);
  const [userDailyInfo, setUserDailyInfo] = useState(0);
  const [timer, setTimer] = useState({});
  const { showConnectWallet, setShowConnectWallet } = useContext(UserContext);
  const connectedAddress = useTonAddress();
  const { userCredits } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });
  const dataAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const bottomAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const leftAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 300 },
  });
  useEffect(() => {
    async function fetchStats() {
      try {
        const [onlineUserRes, metricsRes] = await Promise.all([
          fetch(`${SERVER_ENDPOINT}/v1/user/online`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }),
          fetch(`${SERVER_ENDPOINT}/v1/appmetric`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }),
        ]);
        const online = await onlineUserRes.json();
        const appMetrics = await metricsRes.json();
        if (online.status === "SUCCESS") setOnlineUsers(online.data.count);
        if (appMetrics.status === "SUCCESS") {
          appMetrics.data.forEach((appMetric) => {
            if (appMetric.name === "credits") setTotalCredits(appMetric.value);
            else if (appMetric.name === "players")
              setTotalUsers(appMetric.value);
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchRankings() {
      try {
        const [allTimeRes, dailyRes] = await Promise.all([
          fetch(`${SERVER_ENDPOINT}/v1/user/ranking`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }),
          fetch(`${SERVER_ENDPOINT}/v1/user/ranking/daily`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }),
        ]);
        const parsedDaily = await dailyRes.json();
        const parsedAllTime = await allTimeRes.json();
        console.log(parsedDaily.data.user, parsedAllTime.data);

        if (parsedDaily.status === "SUCCESS") {
          setDailyRanking(parsedDaily.data.ranking);
          setUserDailyInfo(parsedDaily.data.user);
        }
        if (parsedAllTime.status === "SUCCESS") {
          setAllTimeRanking(parsedAllTime.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchRankings();
    fetchStats();
    function updateTimer() {
      const duration = moment.duration(
        moment.utc().endOf("day").diff(moment().utc())
      );
      setTimer({
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, []);

  const truncateName = (name, maxLength = 12) => {
    const nameStr = String(name); // Ensure name is a string
    if (nameStr.length <= maxLength) return nameStr;
    return `${nameStr.slice(0, maxLength)}...`;
  };

  return (
    <div className={styles.body}>
      <div className={styles.overlay}>
        <div className={styles.radial1}></div>
        <div className={styles.radial2}></div>

        <div className={styles.statContent}>
          <animated.div style={{ ...topAnimation }}>
            <div className={styles.pfBar}>
              <ProfileBar />
            </div>
          </animated.div>

          <animated.div style={{ ...dataAnimation }}>
            <div className={styles.dataBox}>
              <div className={styles.columnBox}>
                <div className={styles.dataColumn}>
                  <div className={styles.head}>Total Tap Credits</div>
                  <div className={styles.count}>
                    {getReadableNumber(totalCredits)}
                  </div>
                </div>
                <div className={styles.dataColumn}>
                  <div className={styles.head}>Total Players</div>
                  <div className={styles.count}>
                    {getReadableNumber(totalUsers)}
                  </div>
                </div>
                <div className={styles.dataColumn}>
                  <div className={styles.head}>Total Online</div>
                  <div className={styles.count}>
                    {getReadableNumber(onlineUsers * 50)}
                  </div>
                </div>
              </div>
            </div>
          </animated.div>
          <animated.div style={{ ...leftAnimation }}>
            {activeTab === "daily" && (
              <div className={styles.competeTime}>
                <div className={styles.compBox}>
                  <div>
                    <img src={medal} className={styles.medalIcon} alt="medal" />
                  </div>
                  <div className={styles.compText}>
                    COMPETITION ENDS IN {timer.hours}:{timer.minutes}:
                    {timer.seconds}
                  </div>
                </div>
              </div>
            )}
          </animated.div>
          <animated.div style={{ ...bottomAnimation }}>
            <div className={styles.toggleBox}>
              {/* write toggle box code here */}
              <div className={styles.toggleButtons}>
                <button
                  className={`${styles.toggleButton} ${
                    activeTab === "daily" ? styles.active : ""
                  }`}
                  onClick={() => handleTabClick("daily")}
                >
                  DAILY
                </button>
                <button
                  className={`${styles.toggleButton} ${
                    activeTab === "allTime" ? styles.active : ""
                  }`}
                  onClick={() => {
                    handleTabClick("allTime");
                  }}
                >
                  ALL TIME
                </button>
              </div>
              <div className={styles.statsList}>
                {activeTab === "daily" && (
                  <div className={styles.yourRankBox}>
                    <div className={styles.yourRank}>
                      YOUR POSITION :{" "}
                      <span>
                        {userDailyInfo.userFound ? userDailyInfo.rank : "NA"}
                      </span>
                    </div>
                    <div className={styles.yourCreditsBox}>
                      <div>
                        <img
                          src={CreditsMoney}
                          className={styles.yourCreditsIcon}
                        />
                      </div>
                      <div className={styles.yourCreditCount}>
                        {userDailyInfo.userFound ? userDailyInfo.credits : "NA"}
                      </div>
                    </div>
                  </div>
                )}
                {(activeTab === "daily" ? dailyRanking : allTimeRanking).map(
                  (
                    {
                      name,
                      credits,
                      profileImgUrl,
                      rank,
                      kycCompleted,
                    },
                    index
                  ) => (
                    <div key={index + 1} className={styles.statsItem}>
                      <div className={styles.ppl}>
                        <img
                          src={rankIcons[rank] || rookie}
                          className={styles.ppLevel}
                          alt="ppLevel"
                        />
                        <div className={styles.profilepic}>
                          <img
                            src={profileImgUrl ? profileImgUrl : frogImg}
                            alt={name}
                            className={styles.profilePic}
                          />
                        </div>
                      </div>
                      <div className={styles.userName}>
                        {truncateName(name ? name : "User")}
                        {kycCompleted && (
                          <img
                            src={bluecheck}
                            className={styles.checkIcon}
                            alt="verifiedIcon"
                          />
                        )}
                      </div>
                      <div className={styles.userScore}>
                        {index === 0 && (
                          <div className={styles.rankIconBox}>
                            <img
                              src={rank1Icon}
                              alt="rank1"
                              className={styles.rankIcon}
                            />
                          </div>
                        )}
                        {index === 1 && (
                          <div className={styles.rankIconBox}>
                            <img
                              src={rank2Icon}
                              alt="rank2"
                              className={styles.rankIcon}
                            />
                          </div>
                        )}
                        {index === 2 && (
                          <div className={styles.rankIconBox}>
                            <img
                              src={rank3Icon}
                              alt="rank3"
                              className={styles.rankIcon}
                            />
                          </div>
                        )}
                        <div className={styles.userScoreCount}>
                          {getReadableNumber(credits)}
                        </div>
                      </div>
                      <img
                        src={CreditsMoney}
                        alt="coin"
                        className={styles.userIcon}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </animated.div>
          <div className={styles.bottomNav}>
            <BottomButtons page={page} setPages={setPages} />
          </div>
          <WalletModal
            connectedAddress={connectedAddress}
            showConnectWallet={showConnectWallet}
            setShowConnectWallet={setShowConnectWallet}
          />
        </div>
      </div>
    </div>
  );
};

export default Stats;
