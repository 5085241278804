import React, { useState } from "react";
import { SERVER_ENDPOINT } from "../../utils/constants";
import "./Stepper.css";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import coinImg from "../../media/tap_credits.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Stepper = ({ setStepCompleted, user, setUser, userID }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const steps = [Step1, Step2, Step3, Step4];
  const CurrentStepComponent = steps[currentStep];

  const handleNext = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // vibration on tap
    } else if (window.Telegram?.WebApp)
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleSkip = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // vibration on tap
    } else if (window.Telegram?.WebApp) {
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    }
    // Set the "skip" flag in sessionStorage
    console.log("check");
    sessionStorage.setItem("stepperSkipped", "true");
    setStepCompleted(true);
  };

  const handleClaim = async () => {
    try {
      if (navigator.vibrate) {
        navigator.vibrate(50); // vibration on tap
      } else if (window.Telegram?.WebApp)
        window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");

      // Coin animation
      triggerCoinAnimation();

      const res = await fetch(
        `${SERVER_ENDPOINT}/v1/user/update-credits/${userID}`,
        {
          method: "GET",
        }
      );
      const data = await res.json();
      console.log("Success response from server:", data);
      toast.success("Claimed 50,000 Credits");
      setTimeout(() => {
        setStepCompleted(true);
        setUser({ ...user, saleopt: true });
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
      setStepCompleted(true);
    }
  };

  const triggerCoinAnimation = () => {
    const claimButton = document.querySelector(".claimBox");
    const container = document.querySelector(".stepper-container");

    // Get the bounding box of the claim button to center the coins
    const buttonRect = claimButton.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Calculate the center of the button relative to the container
    const centerX = buttonRect.left + buttonRect.width / 2 - containerRect.left;
    const centerY = buttonRect.top + buttonRect.height / 2 - containerRect.top;

    for (let i = 0; i < 10; i++) {
      const coin = document.createElement("img");
      coin.src = coinImg; // Use the same coin image
      coin.classList.add("coin-animation");

      // Set the initial position to the center of the claim button
      coin.style.position = "absolute";
      coin.style.left = `${centerX}px`;
      coin.style.top = `${centerY}px`;

      // Randomly assign a data attribute to control the animation direction
      coin.style.setProperty("--x-random", `${Math.random() * 300 - 150}px`); // Random left (-150px) to right (+150px)
      coin.style.setProperty("--y-random", `${Math.random() * -400 - 200}px`); // Always negative to move upwards

      // Add the coin to the container
      container.appendChild(coin);

      // Remove the coin after animation ends
      setTimeout(() => {
        coin.remove();
      }, 1200); // Shortened to match faster animation
    }
  };

  return (
    <div className="stepper-container" style={{ background: "black" }}>
      <CurrentStepComponent />

      {/* bottom */}
      <div className="Stepper_bottom">
        <div className="stepper_progress-bar">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`stepper_progress-segment ${
                index <= currentStep ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
        {currentStep !== steps.length - 1 ? (
          <div className="stepper_buttons">
            <button onClick={handleSkip} className="SkipButton">
              Skip
            </button>
            <button onClick={handleNext} className="NextButton">
              Next
            </button>
          </div>
        ) : (
          <div className="claimBox" onClick={handleClaim}>
            <p>CLAIM 50,000</p>
            <img src={coinImg} className="coinIcon" alt="coinIcon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Stepper;
