import React, { useContext, useEffect, useState } from "react";
import "./Partners.css"; // External CSS file for styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
// import frogimg from "../../../media/frogImg.png";
// import hasmter from "../../../media/hamster_logo.png";
// import YESCOIN from "../../../media/yescoin_logo.png";
import coinimg from "../../../media/newSilverPepe.png";
import Popup from "../Popup/Popup";
import partnersData from "./newPartnersData.json";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";

const Partners = ({ onPopupOpen, onPopupClose }) => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [claimStatuses, setClaimStatuses] = useState({}); 
  const { user } = useContext(UserContext);

  const handlePartnerClick = (partner) => {
    if (claimStatuses[partner.partner_id]) {
      return;
    }

    setSelectedPartner(partner);
    setPopupOpen(true); // Open the popup
    onPopupOpen(); // Call the prop function to signal the popup is open
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setSelectedPartner(null);
    onPopupClose(); // Call the prop function to close the popup
  };

  const updateClaimStatus = (partnerId) => {
    setClaimStatuses((prevStatuses) => ({
      ...prevStatuses,
      [partnerId]: true, // Mark the claim as completed for the given partner
    }));
  };


  useEffect(() => {
    // Fetch claim status for each partner
    const fetchClaimStatuses = async () => {
      const statuses = {};
      for (const partner of partnersData) {
        try {
          const res = await fetch(`${SERVER_ENDPOINT}/v1/partner/checkClaimStatus`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: user.userID,
              partner_id: partner.partner_id, // Send the partner ID to check status
            }),
          });
          const data = await res.json();
          // console.log("fetch Status: ", data);
          statuses[partner.partner_id] = data.status; // Store status for each partner
        } catch (error) {
          console.error(`Error fetching claim status for partner ${partner.partner_id}:`, error);
          statuses[partner.partner_id] = false; // Assume false if there's an error
        }
      }
      setClaimStatuses(statuses); // Update the state with claim statuses
    };

    fetchClaimStatuses();
  }, []);


  return (
    <>
      <div className="radial1"></div>
      <div className="radial2"></div>
      <div className="container">
        <div className="header">PARTNERS</div>
        {partnersData.sort((a, b) => {
          // Sort based on claimStatuses: `true` means claimed, `false` means not claimed
          const statusA = claimStatuses[a.partner_id] ? 1 : 0;
          const statusB = claimStatuses[b.partner_id] ? 1 : 0;
          return statusA - statusB; // Adjust sorting order as needed
        }).map((partner, index) => (
            <div
            className={`partner ${claimStatuses[partner.partner_id] ? "completed" : ""}`} // Conditionally apply the 'completed' class
            key={index}
            onClick={() => handlePartnerClick(partner)}
          >
            <img
              src={partner.partner_image_url}
              alt={`${partner.partner_name} logo`}
              className="partner-image"
            />
            <div className="partner-info-container">
              <div className="partner-info">
                <div className="title">{partner.partner_name}</div>
                <div className="subtitle">Reward Available</div>
              </div>
              <div className="vertical-line"></div>
              <div className="reward-container">
                <div className="reward">
                  <img src={coinimg} alt="Coin icon" className="coin-image" />
                  <div className="amount">{partner.reward}</div>
                </div>
              </div>
            </div>
            <div className="arrow">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        ))}
        {selectedPartner && (
          <Popup
            isOpen={isPopupOpen} // Pass isPopupOpen to control the Popup
            onClose={handleClosePopup} // Pass the close function
            partner={selectedPartner} // Pass the selected partner
            updateClaimStatus={updateClaimStatus}
          />
        )}
      </div>
    </>
  );
};

export default Partners;
