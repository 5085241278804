import React, { useContext, useEffect, useRef, useState } from "react";
import "./minigame-2.css";
import miniGameTopImg from "../../../media/miniGameTopImg.png";

import frogCoin from "../../../media/pepe_image.png";
import creditCoin from "../../../media/silver-pepe-bg.png";
import bronzeCoin from "../../../media/game-bronze-coin.png";
import gameYellow from "../../../media/gamebg-1.png";
import gameWhat2 from "../../../media/game-what-2.png";
import whatSign from "../../../media/game-what.png";
import gameCoinSilver from "../../../media/game-silver-coin.png";
import gameGoldCoin from "../../../media/game-gold-coin.png";
import toast from "react-hot-toast";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";
import gameBg from "../../../media/game-what-1.png";
import { MdClose } from "react-icons/md";
import congoMsg from "../../../media/game-congo-msg.png";
import gameCongoCoin from "../../../media/game-gold-coin.png";
import CustomPopup from "../../Shared/CustomPopup/CustomPopup";
import Confetti from "react-confetti";
import CoinAnimationButton from "../../Shared/CoinAnimation/CoinAnimation";

const prizes = ["bronze", "silver", "gold", "frog"];
const val = {
  bronze: 100000,
  silver: 250000,
  gold: 500000,
  frog: 2000000,
};

const image = {
  bronze: bronzeCoin,
  silver: gameCoinSilver,
  gold: gameGoldCoin,
  frog: frogCoin,
};

const MiniGame2 = () => {
  const [result, setResult] = useState("");
  const [boxes, setBoxes] = useState([]);
  const [isClicked, setIsClicked] = useState(Array(12).fill(false));
  const [countScratch, setCountScratch] = useState(0);
  const [show, setShow] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [updatedCredits, setUpdatedCredits] = useState();
  const { jwt } = useContext(AuthContext);
  const [isCreditsLoaded, setIsCreditsLoaded] = useState(false);
  const [displayedCredits, setDisplayedCredits] = useState(0); // For animation
  const { userCredits, setUserCredits } = useContext(UserContext);
  const hasRun = useRef(false);
  const navigate = useNavigate();
  const [token, setToken] = useState("");

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      const hasLoaded = sessionStorage.getItem("hasLoaded");
      if (hasLoaded) {
        // This is a reload, navigate to root
        navigate("/");
      } else {
        // This is the first load, set the flag
        sessionStorage.setItem("hasLoaded", "true");
      }
    };
    handlePageLoad();
    // Cleanup function to remove the flag when component unmounts
    return () => {
      sessionStorage.removeItem("hasLoaded");
    };
  }, [navigate]);

  const incrementCredits = (newCredits) => {
    const difference = newCredits - userCredits;
    let currentCredits = userCredits;
    const increment = Math.ceil(difference / 50); // Number of steps for the animation

    const interval = setInterval(() => {
      currentCredits += increment;
      if (currentCredits >= newCredits) {
        currentCredits = newCredits;
        clearInterval(interval);
      }
      setDisplayedCredits(currentCredits);
    }, 30); // Speed of animation in ms
  };

  const handleClaim = (newCredits) => {
    setUserCredits(newCredits); // Update the user's credits
    incrementCredits(newCredits); // Animate the increment
  };

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  const debounceClick = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const cardClicked = debounceClick((value, index) => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // vibration on tap
    } else if (window.Telegram?.WebApp)
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    if (isClicked[index]) return;
    if (value === result) {
      if (countScratch + 1 === 3) {
        setShow(true);
        async function addCredits() {
          try {
            // const credit = val[result];
            const response = await fetch(
              `${SERVER_ENDPOINT}/v1/puzzle/addCredits`,
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                  tap: result,
                  token
                }),
              }
            );
            const parsedResponse = await response.json();

            if (parsedResponse.status === "SUCCESS") {
              setUpdatedCredits(parsedResponse?.data?.credits);
              setIsCreditsLoaded(true);
            }
          } catch (error) {
            console.log(error);
          }
        }
        addCredits();
      }
      setCountScratch(countScratch + 1);
    }
    const newClickedStates = [...isClicked];
    newClickedStates[index] = true;
    setIsClicked(newClickedStates);
  }, 300);

  useEffect(() => {
    setDisplayedCredits(userCredits); // Initialize the displayed credits
  }, [userCredits]);
  useEffect(() => {
    const preloadImages = () => {
      const imageUrls = Object.values(image);
      let loadedImages = 0;
      const totalImages = imageUrls.length;
      const onImageLoad = () => {
        loadedImages += 1;
        if (loadedImages === totalImages) {
          setImagesLoaded(true);
        }
      };

      imageUrls.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = onImageLoad;
      });
    };

    preloadImages();
  }, []);
  useEffect(() => {
    async function fetchResult() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/puzzle/claimNewOutcome`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();
        setResult(parsedResponse?.data);
        setToken(parsedResponse?.token);
        const index = prizes.indexOf(parsedResponse?.data);
        let array = [];
        for (let i = 0; i < 12; i++) {
          if (i < 6) array.push(prizes[index]);
          else if (i < 8) array.push(prizes[(index + 1) % 4]);
          else if (i < 10) array.push(prizes[(index + 2) % 4]);
          else array.push(prizes[(index + 3) % 4]);
        }
        setBoxes(shuffle(array));
      } catch (error) {
        console.log(error);
      }
    }
    if (!hasRun.current) {
      hasRun.current = true;
      fetchResult();
    }
  }, []);

  return (
    <div className="miniGame_2_MainDiv">
      {imagesLoaded ? (
        <>
          <div className="miniGameContentWrapper">
            {show && (
              <div className="game_2_confeti">
                <Confetti width={820} recycle={false} />
              </div>
            )}
            {show && (
              <div className={`completeModal ${show ? "show" : "hide"}`}>
                <div className="gameCongoModalContent">
                  <div>
                    <img
                      src={congoMsg}
                      className="congoMsgImg"
                      alt="congratulation message"
                    />
                    <p className="gameCongoTitle">{`YOU WON A ${result}!`}</p>
                    <div className="coinImgParent">
                      <div className="congoCoinBox">
                        <img
                          src={image[result]}
                          alt="coin"
                          className="gameCongoCoin"
                        />
                      </div>
                    </div>
                    <CoinAnimationButton
                      renderIcon={creditCoin}
                      text="Claim"
                      result={val[result]}
                      navigationRoute="/?noload=true"
                      customClass="claimButton"
                      onClaim={handleClaim} // Pass the handler as a prop
                      updatedCredits={updatedCredits}
                      isCreditsLoaded={isCreditsLoaded}
                    />
                  </div>
                </div>
              </div>
            )}
            {showRulesModal && (
              <CustomPopup
                showRulesModal={showRulesModal}
                setShowRulesModal={setShowRulesModal}
              />
            )}
            <div className="topButton">
              <button
                className="miniGameBackBtn"
                onClick={() => navigate("/mini-game")}
              >
                Back
              </button>
              <button
                className="miniGameRules"
                onClick={() => setShowRulesModal(!showRulesModal)}
              >
                Rules
              </button>
            </div>
            <div className="minigame_2_ImgSection">
              <div className="TopImg_2_Section">
                <img src={miniGameTopImg} alt="" className="mini_game_2_logo" />
              </div>
            </div>

            <div className="creditCoinMain">
              <div className="game_2_CreditBox">
                <img src={creditCoin} alt="coin" className="game_2_coinImage" />
                <div>
                  <p className="game_2_creditTitle">My Tap Credits</p>
                  <p className="game_2_amount">{displayedCredits}</p>
                </div>
              </div>
            </div>
            {/* Number box */}
            <div className="numberBoxMain">
              <div className="outsideBox">
                <div className="numberBox_2">
                  <div className="onlyBorder">
                    <div className="NumberContentBox">
                      <div className="mainCoinBox">
                        {boxes.length > 0 &&
                          boxes.map((value, index) => {
                            console.log(value);
                            return (
                              <div
                                key={index}
                                className="box"
                                onClick={() => cardClicked(value, index)}
                              >
                                <img
                                  className="boxImg"
                                  src={isClicked[index] ? image[value] : gameBg}
                                  alt="game"
                                  style={{
                                    transform: isClicked[index]
                                      ? "scale(0.8)"
                                      : "scale(1)", // Example of scaling the image when clicked
                                    transition: "transform 0.3s ease-in-out", // Smooth transition for the scaling effect
                                    filter: isClicked[index]
                                      ? "drop-shadow(0 0 10px gold)"
                                      : "none", // Adding a glow effect
                                    borderRadius: "5px", // Maintaining border radius
                                    objectFit: isClicked[index]
                                      ? "contain"
                                      : "unset",
                                  }}
                                />
                                {/* <div
                                  className="whatSignBox"
                                  style={{
                                    zIndex: isClicked[index] ? 10 : 1,
                                    opacity: isClicked[index] ? 1 : 0,
                                    transition: "all 0.3s ease-in-out",
                                    transform: "translateZ(0)",
                                  }}
                                >
                                  <img
                                    src={image[value]}
                                    alt="coin"
                                    className="whatSign"
                                    style={{ transform: "translateZ(0)" }}
                                  />
                                </div> */}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loader">Loading...</div>
      )}
    </div>
  );
};

export default MiniGame2;
