import React, { useContext, useEffect, useState } from "react";
import "./Popup.css"; // Import the scoped CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import tweeterIcon from "../../../media/x_color_logo.png";
import youtubeIcon from "../../../media/yt_color_logo.png";
import telegramIcon from "../../../media/tg_color_logo.png";
import playIcon from "../../../media/play_color_logo.png";

import tweeterIconGreen from "../../../media/twitter_green.png";
import youtubeIconGreen from "../../../media/youtube_green.png";
import telegramIconGreen from "../../../media/telegram_green.png";
import playIconGreen from "../../../media/gamplay_green.png";

import { SERVER_ENDPOINT } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";
import toast from "react-hot-toast";

const getImage = {
  youtube: youtubeIcon,
  telegram: telegramIcon,
  playIcon: playIcon,
  tweeterIcon: tweeterIcon,
};

const getCompletedImage = {
  youtube: youtubeIconGreen,
  telegram: telegramIconGreen,
  playIcon: playIconGreen,
  tweeterIcon: tweeterIconGreen,
};

function Popup({ isOpen, onClose, partner, updateClaimStatus }) {
  const [showPopup, setShowPopup] = useState(false);
  const { user, setUserCredits } = useContext(UserContext);
  const [taskStatuses, setTaskStatuses] = useState({}); // Store task completion status

  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (partner && partner.tasks) {
      // For each task, check if it is completed and store the result
      partner.tasks.forEach(async (task) => {
        const completed = await isTaskCompleted(task.task_id);
        setTaskStatuses((prev) => ({ ...prev, [task.task_id]: completed }));
      });
    }
  }, [partner]);

  const isTaskCompleted = async (task) => {
    try {
      const res = await fetch(`${SERVER_ENDPOINT}/v1/mapping/taskCompletedByUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          task_id: task,
          user_id: user.userID,
        }),
      });
      const data = await res.json();
      return data.status ? true : false;
    } catch (error) {
      console.error("Error checking task completion status:", error);
      return false;
    }
  };

  const openLink = async (task) => {
    window.open(task.task_url, "_blank");
    try {
      const requestBody = {
        user_id: user.userID,
        task: {
          task_id: task.task_id,
          task_name: task.task_name,
          partner_id: partner.partner_id,
          credits: task.credits,
        },
      };
      const res = await fetch(`${SERVER_ENDPOINT}/v1/task/taskComplete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      let response = await res.json();
      if (response.status) {

        setTimeout(() => {
          setTaskStatuses((prev) => ({
            ...prev,
            [task.task_id]: true,
          }));
          toast.success(response.message);
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClaim = async () => {
    const allTasksCompleted = Object.values(taskStatuses).every(
      (status) => status === true
    );

    if (!allTasksCompleted) {
      toast.error("Please complete all tasks to claim the reward.");
      return;
    }

    try {
        const creditRes = await fetch(`${SERVER_ENDPOINT}/v1/user/getPartnerCredits`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user.userID,
            partner_id: partner.partner_id,
          }),
        });

        const creditData = await creditRes.json();
        // console.log(creditData);
        if (creditData.status) {
          console.log("newCredits: ",creditData.newCreditsValue);
          toast.success(creditData.message);
          updateClaimStatus(partner.partner_id);
          setUserCredits(creditData.newCreditsValue)
          setTimeout(() => {
            onClose(); // Close the popup
          }, 300);
        } 
        else {
          toast.error(creditData.message);
        }
      } 
      catch (creditError) {
        console.log(creditError);
      }    
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className={`popup-container ${showPopup ? "show" : ""}`}>
        <button
          className="popup-close-btn"
          onClick={onClose}
          aria-label="Close popup"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <img
          src={partner.partner_image_url}
          alt="Partner logo"
          className="popup-image"
        />
        <h1 className="popup-title">{partner.partner_name}</h1>
        <div className="popup-description">
          Accomplish all the tasks and <br />claim your reward
        </div>
        <div className="popup-icons">
          {partner.tasks.map((task, index) => (
            <div
              key={index}
              className="popup-icon-wrapper"
              onClick={() => openLink(task)}
            >
              <img
                src={
                  taskStatuses[task.task_id]
                    ? getCompletedImage[task.task_image]
                    : getImage[task.task_image]
                }
                alt={`${task.task_name} icon`}
              />
              <span className="popup-icon-text">{task.label}</span>
            </div>
          ))}
        </div>
        <button className="popup-claim-btn" onClick={handleClaim}>
          CLAIM {partner.reward}
        </button>
      </div>
    </div>
  );
}

export default Popup;
