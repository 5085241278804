import React, { useEffect, useState, useRef } from "react";
import "./privatesale.css";
import pepePrivate from "../../../media/pepe_image.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";

const PrivateSaleContent = ({ details, setDetails }) => {
  const [totalUser, setTotalUsers] = useState(2350);
  const [totalUserGame, setTotalUsersGame] = useState(2350);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [sol, setSol] = useState(650.3);
  const [ton, setTon] = useState(15523.2);
  const currentPrice = useRef(0);
  const twentyHourPrice = useRef(0);
  const thirtyDayPrice = useRef(0);
  const ytdPrice = useRef(0);
  const lpSolPrice = useRef(0);
  const usdTonPrice = useRef(0);

  useEffect(() => {
    async function totalUsersDapp() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/total-users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();

        setTotalUsers(parsedResponse.totalUsers);
      } catch (error) {
        console.log(error);
      }
    }

    async function totalUsersGame() {
      try {
        const response = await fetch(
          `https://game-prodapi.pepefrogbar.com/v1/appmetric/packageOwners`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTotalUsersGame(parsedResponse.data.value);
      } catch (error) {
        console.log(error);
      }
    }

    async function tokenPriceVariation() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/token-variation`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        currentPrice.current = parsedResponse.result.currentPrice;
        twentyHourPrice.current = parsedResponse.result.twentyFourPrice;
        thirtyDayPrice.current = parsedResponse.result.thirtyDayPrice;
        ytdPrice.current = parsedResponse.result.YTDPrice;
      } catch (error) {
        console.log(error);
      }
    }

    async function solPrice() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/public-token-price`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setSol(parsedResponse.solPrice);
        console.log("lpSolBalance:", parsedResponse.lpSolBalance);
        lpSolPrice.current = parsedResponse.lpSolBalance;
      } catch (error) {
        console.log(error);
      }
    }
    async function tonPrice() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/appmetric/packages/value`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTon(parsedResponse.data.balanceTon);
        usdTonPrice.current = parsedResponse.data.usdValue;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTotalTransactions() {
      try {
        const response = await fetch(
          `https://game-prodapi.pepefrogbar.com/v1/transaction/successcount`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTotalTransactions(parsedResponse.count);
      } catch (error) {
        console.log(error);
      }
    }

    solPrice();
    tonPrice();
    tokenPriceVariation();
    totalUsersDapp();
    totalUsersGame();
    fetchTotalTransactions();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "700px",
        paddingBottom: "10px",
      }}
    >
      <img
        style={{ width: "90px", height: "90px", position: "relative" }}
        alt="pepe"
        src={pepePrivate}
        className="ringImg"
      />
      <div className="privateSalePepe">PEPE’s Frogbar Private SALE</div>

      <div
        style={{
          width: "95%",
          height: "158px",
          borderRadius: "8px",
          backgroundColor: "rgba(33, 17, 52, 0.6)",
          zIndex: "1",
        }}
      >
        <div className="privateSaleHeadings">PRIVATE SALE LIVE PRICE</div>
        <hr
          style={{
            border: "1px solid #FFFFFF26",
            marginLeft: "7px",
            marginRight: "7px",
          }}
        />
        <div
          style={{
            fontFamily: "DIN 2014",
            fontSize: "28px",
            fontWeight: "500",
            lineHeight: "32px",
            letterSpacing: "0.05em",
            textAlign: "center",
            color: "#ffffff",
          }}
        >
          ${currentPrice.current.toFixed(10)}
        </div>

        <div
          style={{
            width: "198px",
            height: "40px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            margin: "auto",
            marginTop: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              height: "100%",
              alignItems: "center",
              background: "rgba(255, 255, 255, 0.1)",
            }}
          >
            <div
              style={{
                width: "46px",
                height: "22px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "DIN 2014",
                  fontWeight: "700",
                  color: "#ffffffff",
                  fontSize: "12px",
                }}
              >
                {" "}
                24 hours{" "}
              </div>
              <div style={{ color: "#83da6e", fontSize: "10px" }}>
                {" "}
                ▲{" "}
                {(
                  ((currentPrice.current - twentyHourPrice.current) /
                    twentyHourPrice.current) *
                  100
                ).toFixed(2)}{" "}
                %{" "}
              </div>
            </div>
            <div
              style={{
                height: "100%",
                top: "0",
                margin: "0",
                border: "1px solid rgba(255, 255, 255, 0.1)",
              }}
            ></div>
            {/* <div
              style={{
                width: "46px",
                height: "22px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "DIN 2014",
                  fontWeight: "700",
                  color: "#ffffffff",
                  fontSize: "12px",
                }}
              >
                {" "}
                30 days{" "}
              </div>
              <div style={{ color: "#83da6e", fontSize: "10px" }}>
                {" "}
                ▲{" "}
                {(
                  ((currentPrice.current - thirtyDayPrice.current) /
                    thirtyDayPrice.current) *
                  100
                ).toFixed(2)}{" "}
                %{" "}
              </div>
            </div> */}
            {/* <div
              style={{
                height: "100%",
                top: "0",
                margin: "0",
                border: "1px solid rgba(255, 255, 255, 0.1)",
              }}
            ></div> */}
            <div
              style={{
                width: "46px",
                height: "22px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "DIN 2014",
                  fontWeight: "700",
                  color: "#ffffffff",
                  fontSize: "12px",
                }}
              >
                {" "}
                YTD{" "}
              </div>
              <div style={{ color: "#83da6e", fontSize: "10px" }}>
                {" "}
                ▲{" "}
                {(
                  ((currentPrice.current - 0.0000045) / 0.0000045) *
                  100
                ).toFixed(2)}{" "}
                %{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "95%",
          height: "230px",
          borderRadius: "8px",
          backgroundColor: "rgba(33, 17, 52, 0.6)",
        }}
      >
        <div className="privateSaleHeadings">STATISTICS</div>
        <hr
          style={{
            border: "1px solid #FFFFFF26",
            marginLeft: "7px",
            marginRight: "7px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "75%",
            padding: "0 5px",
          }}
        >
          <div
            style={{
              width: "95%",
              height: "26px",
              color: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 2%",
            }}
          >
            <div
              style={{
                fontFamily: "Oswald",
                fontSize: "17px",
                fontWeight: "500",
                lineHeight: "26px",
              }}
            >
              Total Holders
            </div>
            {/* <div>2092</div> */}
            {/* <div>1552</div> */}
            <div>{totalTransactions * 3 + totalUser + 100}</div>
          </div>
          <div
            style={{
              width: "95%",
              height: "26px",
              color: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 2%",
            }}
          >
            <div
              style={{
                fontFamily: "Oswald",
                fontSize: "17px",
                fontWeight: "500",
                lineHeight: "26px",
              }}
            >
              Liquidity Raised (SOL)
            </div>
            <div>{lpSolPrice.current.toFixed(2)}</div>
          </div>
          <div
            style={{
              width: "95%",
              height: "26px",
              color: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 2%",
            }}
          >
            <div
              style={{
                fontFamily: "Oswald",
                fontSize: "17px",
                fontWeight: "500",
                lineHeight: "26px",
              }}
            >
              Liquidity Raised (TON)
            </div>
            <div>{ton.toFixed(2)}</div>
          </div>
          <div
            style={{
              width: "95%",
              height: "26px",
              color: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 2%",
            }}
          >
            <div
              style={{
                fontFamily: "Oswald",
                fontSize: "17px",
                fontWeight: "500",
                lineHeight: "26px",
              }}
            >
              Total Liquidity (USDT)
            </div>
            <div>
              ${(usdTonPrice.current + sol * lpSolPrice.current).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setDetails(true)}
        style={{
          width: "95%",
          height: "40px",
          borderRadius: "26px",
          background: "#D87500",
          font: "Oswald",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#ffffff",
        }}
      >
        JOIN NOW
      </div>
    </div>
  );
};

export default PrivateSaleContent;
